<template>
  <transition name="modal">
    <div class="modal-mask">
    <div class="modal-wrapper">
    <div class="modal-container">

    <div class="modal-header font-weight-bold">
      <slot name="header">Promotion Code Detail for '{{passedPromotionCode.promotionCode}}'</slot>
    </div>

    <div class="modal-body mt-0 mb-0">
      <form>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="partnerName" class="text-dark">Partner Name</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'partnerName', index: 0, value: passedPromotionCode.partnerName}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{passedPromotionCode.partnerName}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="groupName" class="text-dark">Group Name</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'groupName', index: 0, value: passedPromotionCode.groupName}" @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{passedPromotionCode.groupName}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="promotionCode" class="text-dark">Promotion Code</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'promotionCode', index: 0, value: passedPromotionCode.promotionCode}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedPromotionCode.promotionCode}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="displayMessage" class="text-dark">Display Message</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'displayMessage', index: 0, value: passedPromotionCode.displayMessage}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedPromotionCode.displayMessage}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="discountAmount" class="text-dark">Discount Amount</label>
          </div>
          <div class="form-group col-md-2">
            <CurrencyInputField v-if="isAdmin" :passedData="{name: 'discountAmount', index: 0, value: passedPromotionCode.discountAmount}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(passedPromotionCode.discountAmount)}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="discountPercent" class="text-dark">Discount Percent</label>
          </div>
          <div class="form-group col-md-2">
            <PercentageInputField v-if="isAdmin" :passedData="{name: 'discountPercent', index: 0, value: passedPromotionCode.discountPercent}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{formatPercentage(passedPromotionCode.discountPercent)}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="discountLimit" class="text-dark">Discount Limit</label>
          </div>
          <div class="form-group col-md-2">
            <CurrencyInputField v-if="isAdmin" :passedData="{name: 'discountLimit', index: 0, value: passedPromotionCode.discountLimit}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(passedPromotionCode.discountLimit)}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="donationEligible" class="text-dark">Donation Eligible</label>
          </div>
          <div class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'donationEligible', index: 0, value: passedPromotionCode.donationEligible}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{(passedPromotionCode.donationEligible) ? "Yes" : "No"}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="donationRecipient" class="text-dark">Donation Recipient</label>
          </div>
          <div class="form-group col-md-8">
              <TextInputField v-if="isAdmin" :passedData="{name: 'donationRecipient', index: 0, value: passedPromotionCode.donationRecipient}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{passedPromotionCode.donationRecipient}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="active" class="text-dark">Active</label>
          </div>
          <div class="form-group col-md-3">
            <BooleanInputField v-if="isAdmin" :passedData="{name: 'active', index: 0, value: passedPromotionCode.active}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{(passedPromotionCode.active) ? "Yes" : "No"}}</label>
          </div>
        </div>

        <div class="form-row ml-2 mr-2">
          <div class="form-group col-md-4 text-right">
            <label for="expirationDate" class="text-dark">Expires</label>
          </div>
          <div class="form-group col-md-3">
            <DateInputField v-if="isAdmin" :passedData="{name: 'expirationDate', index: 0, value: passedPromotionCode.expirationDate.substring(0,10)}" @input="changeFromComponent"/>
            <label v-if="!isAdmin" class="custom-fg-dark">{{formatDate(passedPromotionCode.expirationDate, 'MM/DD/YYYY')}}</label>
          </div>
        </div>

      </form>
    </div>

    <div class="modal-footer">
      <div class="col-auto">
        <div class="container mt-2">
          <div class="row">
            <div class="col">
              <button class="btn btn-secondary mt-2" @click.prevent="close()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
    </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'

import BooleanInputField from '@/components/util/BooleanInputField'
import CurrencyInputField from '@/components/util/CurrencyInputField'
import DateInputField from '@/components/util/DateInputField'
import PercentageInputField from '@/components/util/PercentageInputField'
import TextInputField from '@/components/util/TextInputField'

import Store from '@/store'

export default {
  name: 'promotionCodeModal',
  components: {
    BooleanInputField,
    CurrencyInputField,
    DateInputField,
    PercentageInputField,
    TextInputField
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    }
  },
  created: async function () {
    this.promotionCode = this.passedPromotionCode;
  },
  data: function () {
    return {
      promotionCode: {}
    }
  },
  methods: {
    changeFromComponent: function(returnData) {
      var name = returnData.name;
      var newValue = returnData.newValue;

      this.promotionCode[name] = newValue;
      this.$emit('change', this.promotionCode);
    },
    close: function () {
      this.$emit('close');
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatDate: function (date, format) {
      var ret = date;

      if (date != null) {
        ret = moment(date.substring(0, 10)).format(format);
      }

      return ret;
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.00%');
    },
  },
  props: ['passedPromotionCode'],
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 900px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #18752E;
}

.modal-body {
  margin: 20px 0;
  color: #18752E;
}

.modal-footer {
  color: #18752E;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
