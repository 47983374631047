<template>

  <div class="container">

    <div id="PromotionCodeModal">
      <PromotionCodeModal v-if="showDetailModal" :passedPromotionCode="selectedPromotionCode" @close="showDetailModal=false" @change="changeFromModal"/>
    </div>

    <p>
      <span>
        [ <router-link :to="{name: 'Applications'}" class="custom-fg-dark">Return to Application Listing</router-link> ]
      </span>
    </p>

    <div class="card mt-4" id="results">

      <div class="card-header">
        <div class="row">
          <div class="col-4">
            <h5>Promotion Code Administration</h5>
            <small>Double click for full detail</small>
          </div>
          <div class="col-4">
            <input id="filter" placeholder="Filter by Partner, Group or Code" type="text" class="form-control" v-model="searchTerm">
          </div>
          <div v-if="isAdmin" class="col-4" style="text-align:right;">
            <div class="col-auto custom-cursor-pointer mt-2" @click="addPromotionCode()">
              <i class="fa fa-plus-circle customer-fg-dark mr-2" aria-hidden="true" />
              <span>Add Code</span>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-sm table-hover mb-0">
        <thead class="custom-bg-med">
          <tr>
            <th scope="col">Partner Name</th>
            <th scope="col">Promotion Code</th>
            <th scope="col">Discount Amount</th>
            <th scope="col">Discount Percent</th>
            <th scope="col">Discount Limit</th>
            <th scope="col">Active</th>
            <th scope="col">Expires</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody v-cloak>
          <tr v-for="(promotionCode, index) in filteredResult" :key="promotionCode.id" v-on:dblclick="showPromotionCodeModal(promotionCode)">
            <td>
              <TextInputField
                v-if="isAdmin"
                :key="promotionCode.partnerName"
                :passedData="{name: 'partnerName', index: index, value: promotionCode.partnerName}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{promotionCode.partnerName}}</label>
            </td>
            <td>
              <TextInputField
                v-if="isAdmin"
                :key="promotionCode.promotionCode"
                :passedData="{name: 'promotionCode', index: index, value: promotionCode.promotionCode}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{promotionCode.promotionCode}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField
                v-if="isAdmin"
                :key="promotionCode.discountAmount"
                :passedData="{name: 'discountAmount', index: index, value: promotionCode.discountAmount}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(promotionCode.discountAmount)}}</label>
            </td>
            <td class="text-right">
              <PercentageInputField
                v-if="isAdmin"
                :key="promotionCode.discountPercent"
                :passedData="{name: 'discountPercent', index: index, value: promotionCode.discountPercent}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{promotionCode.discountPercent}}</label>
            </td>
            <td class="text-right">
              <CurrencyInputField
                v-if="isAdmin"
                :key="promotionCode.discountLimit"
                :passedData="{name: 'discountLimit', index: index, value: promotionCode.discountLimit}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(promotionCode.discountLimit)}}</label>
            </td>
            <td>
              <BooleanInputField
                v-if="isAdmin"
                :key="promotionCode.active"
                :passedData="{name: 'active', index: index, value: promotionCode.active}"
                @input="changeFromComponent"/>
              <label v-if="!isAdmin" class="custom-fg-dark">{{formatCurrency(promotionCode.active)}}</label>
            </td>
            <td>
              <label class="custom-fg-dark">{{formatDate(promotionCode.expirationDate, 'MM/DD/YYYY')}}</label>
            </td>
            <td class="text-right custom-cursor-pointer">
              <i v-if="isAdmin" class="fa fa-trash" aria-hidden="true" @click="deletePromotionCode(promotionCode)" />
            </td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>

</template>

<script>
import lodash from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import Store from '@/store'

import PromotionCode from '@/services/PromotionCodeService'

import PromotionCodeModal from '@/components/modals/PromotionCodeModal'

import BooleanInputField from '@/components/util/BooleanInputField'
import CurrencyInputField from '@/components/util/CurrencyInputField'
import PercentageInputField from '@/components/util/PercentageInputField'
import TextInputField from '@/components/util/TextInputField'

export default {
  name: 'promotionCodes',
  components: {
    BooleanInputField,
    CurrencyInputField,
    PercentageInputField,
    PromotionCodeModal,
    TextInputField
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  },
  computed: {
    isAdmin: function() {
      return (Store.state.userInfo != null) ? Store.state.userInfo.admin : false;
    },
    filteredResult: function() {
      return this.promotionCodes.filter(result => {
        return (
          result.partnerName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.groupName.toLowerCase().match(this.searchTerm.toLowerCase()) ||
          result.promotionCode.toLowerCase().match(this.searchTerm.toLowerCase())
        );
      });
    },
  },
  created: async function () {
    this.promotionCodes = await PromotionCode.getAllPromotionCodes();
  },
  data: function () {
    return {
      promotionCodes: [],
      searchTerm: "",
      showDetailModal: false,
      selectedPromotionCode: {}
    }
  },
  methods: {
    addPromotionCode: async function() {
      var promotionCode = {
        partnerName: "Change Me",
        groupName: "Change Me",
        promotionCode: "Change Me",
        displayMessage: "",
        discountAmount: 0.0,
        donationEligible: false,
        donationRecipient: "",
        discountPercent: 0.0,
        discountLimit: 0,
        active: false,
        expirationDate: moment("01/01/1970", "MM/DD/YYYY").format()
      }

      promotionCode = await PromotionCode.createPromotionCode(promotionCode);
      this.promotionCodes.push(promotionCode);
    },
    changeFromComponent: async function(returnData) {
      let name = returnData.name;
      let idx = returnData.index;
      let promotionCode = this.promotionCodes[idx];
      let newValue = returnData.newValue;

      promotionCode[name] = newValue;
      this.promotionCodes[idx] = await PromotionCode.updatePromotionCode(promotionCode);
    },
    changeFromModal: async function(promotionCode) {
      var idx = lodash.findIndex(this.promotionCodes, {id: promotionCode.id});
      this.promotionCodes[idx] = await PromotionCode.updatePromotionCode(promotionCode);
    },
    deletePromotionCode: async function(promotionCode) {
      //
      // Lodash's without was acting up; doing it the more manual way
      //
      var idx = lodash.findIndex(this.promotionCodes, {id: promotionCode.id});

      this.promotionCodes.splice(idx, 1);

      //
      // Call the end point to delete from the database
      //
      await PromotionCode.deletePromotionCode(promotionCode.id);
    },
    formatCurrency: function (amount) {
      return numeral(amount).format('$0,0');
    },
    formatDate: function (date, format) {
      var ret = date;

      if (date != null) {
        ret = moment(date.substring(0, 10)).format(format);
      }

      return ret;
    },
    formatPercentage: function (amount) {
      if (amount > 1) {
        amount = amount / 100;
      }
      return numeral(amount).format('0.00%');
    },
    showPromotionCodeModal(promotionCode) {
      this.selectedPromotionCode = promotionCode;
      this.showDetailModal = true;
    }
  }
}
</script>
