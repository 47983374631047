<template>
  <div>
    <input
      type="date"
      class="form-control form-control-sm"
      :id="passedData.name + '_' + passedData.index"
      v-model="newValue"
      @change="changeValue()"
    />
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'dateInputField',
  created: async function () {
    this.newValue = this.passedData.value;
  },
  data: function () {
    return {
      newValue: this.passedData.value,
    }
  },
  methods: {
    changeValue: function() {
      //
      // Dates are a PITA
      //
      var newValueDT = moment(this.newValue, "YYYY-MM-DD");
      var passedValueDT = moment(this.passedData.value, "YYYY-MM-DD");

      if (isNaN(newValueDT) || isNaN(passedValueDT)) {
        return;
      }

      var newValueStr = moment(newValueDT).format("YYYY-MM-DD");
      var passedValueStr = moment(passedValueDT).format("YYYY-MM-DD");

      if (newValueStr != passedValueStr) {
        var returnData = {
          name: this.passedData.name,
          index: this.passedData.index,
          newValue: moment(newValueStr, "YYYY-MM-DD").format(),
          placeHolder: this.passedData.placeHolder,
          value: this.passedData.value
        };

        this.$emit("input", returnData);
      }
    },
  },
  props: ['passedData'],
}

</script>
